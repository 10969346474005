module.exports = [{
      plugin: require('/home/runner/work/arthack.nz/arthack.nz/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/arthack.nz/arthack.nz/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"arthack.nz","short_name":"arthack.nz","start_url":"/","background_color":"#c33764","theme_color":"#1d2671","display":"minimal-ui","icon":"src/images/icon.png"},
    },{
      plugin: require('/home/runner/work/arthack.nz/arthack.nz/node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"4","matomoUrl":"https://analytics.mikey.nz","siteUrl":"https://arthack.nz"},
    }]
